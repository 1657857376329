.withdrawalContainer {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: var(--font-primary);
}

.withdrawalContainer > p {
    color: #fff;
    margin-bottom: 5px;
}

.withdrawalContainer > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 25px;
}