.jsxcontainer {
    background-color: #3c0767;
    padding-bottom: 4.6vw;
    color: white;
    font-family: var(--font-primary);
}

.innerContainer {
    padding: 8px 0;
}

.textParent{
    padding: 0;
}

.text1 {
    margin: 0;
    font-size: clamp(14px, 1.97vw, 38px);
    font-weight: 600;
    font-style: italic;
    letter-spacing: 1.71px;
    text-align: center;
}

.text2 {
    margin: 0;
    font-size: clamp(10px, 1.14vw, 22px);
    font-weight: 500;
    text-align: center;
}

.seperatorLine {
    width: 2.1px;
    background: linear-gradient(rgba(200, 200, 199, 0), #B4B4B5, rgba(151, 151, 151, 0));
}

.partnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;

}

@media screen and (max-width: 576px) {
    .jsxcontainer {
        display: none;
    }
}