.mainContainer {
    display: none;
    font-family: var(--font-primary);
}

@media screen and (max-width: 767px) {
    .mainContainer {
        padding: 13px 15px 15px;
        display: block !important;
    }

    .content {
        font-size: 17px;
        font-weight: 600;
        font-style: italic;
        letter-spacing: -0.32px;
        text-align: center;
        color: #fff;
        margin-bottom: 10px;
        text-transform: uppercase;
    }
}

@media screen and (max-width: 576px) {
    .mainContainer {
        display: block !important;
    }
}