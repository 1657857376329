.imgClass {
    margin: 0 0 0 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.dropdownContent {
    font-family: var(--font-primary);
    font-weight: 900;
    font-size: 12px;
    border-radius: 2px;
    position: absolute;
    background-color: black;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 228px;
    margin-top: 0px;
    left: 150px;
    top: 50px;
}

.anchorTag {
    color: white;
    padding: 10px;
    text-decoration: none;
    display: block;
    text-align: center;
}

.anchorTagLessLang {
    color: white;
    padding: 10px;
    text-decoration: none;
    display: block;
    text-align: center;
}

.anchorTag:hover {
    background: linear-gradient(to bottom, #b1fd2b, #31eb6f);
    border-radius: 2px;
    color: black;
}

.anchorTagLessLang:hover {
    background: linear-gradient(to bottom, #b1fd2b, #31eb6f);
    border-radius: 2px;
    color: black;
}

.active {
    background: linear-gradient(to bottom, #b1fd2b, #31eb6f);
    border-radius: 2px;
    color: black !important;
}

.selectLang {
    pointer-events: none;
    background-color: black;
    text-align: center;
    color: white;
    width: 228px;
    margin: 15px 0;
    font-size: 14px;
}

@media screen and (max-width: 767px) {

    .imgClass {
        margin: 0 2px;
    }

    .dropdownContent {
        width: 100vw;
        left: 0;
        position: absolute;
    }

    .selectLang {
        width: 100vw;
        margin: 10px 0;
    }
}