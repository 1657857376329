.floatingDownloadButtonContainer {
    position: fixed;
    width: 100%;
    z-index: 999;
    bottom: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    background: #fff;
    -webkit-box-shadow: 0 -2px 2px 0 rgb(0 0 0 / 8%);
    box-shadow: 0 -2px 2px 0 rgb(0 0 0 / 8%);
    padding: 10px 20px;
}

.floatingDownloadButtonText {
    background-image: linear-gradient(to top,
    #62258a 67%,
    #b3097f 11%);
    font-family: var(--font-primary), sans-serif;
    font-size: 15px;
    font-weight: 900;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: -0.22px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    text-align: center;
    animation: blinking 1s linear infinite alternate-reverse;
    margin-bottom: 10px;
}

.floatingDownloadButton {
    position: fixed;
    top: 50px;
    left: 0;
    height: calc(100% - 150px);
    width: 100%;
    background-color: #fff;
}

.offerPopupClose {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    cursor: pointer;
}

.videoView {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center
}

.videoSoundControlView {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    cursor: pointer;
    background-color: #ccc;
    padding: 2px
}

@keyframes blinking {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
}

@media screen and (min-width: 767px) {
    .floatingDownloadButtonContainer {
        display: none !important;
    }
}