.withdrawalContainer {
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 15px 4.6vw;
    background-color: #210131;
    color: white;
    font-family: var(--font-primary);
    margin-top: -1px;
}

.withdrawalContainer > p {
    font-size: 16px;
    font-weight: 900;
    text-align: center;
    font-style: italic;
    letter-spacing: 0.57px;
    margin-bottom: 5px;
}

.withdrawalContainer > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 30px;
    margin-bottom: 5px;
}

@media screen and (max-width: 576px) {
    .withdrawalContainer {
        border-top: 1px solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(246, 196, 1, 1) 50%, rgba(255, 255, 255, 0) 100%);
        display: flex;
        padding-top: 8px;
    }

    .withdrawalContainer > div {
        padding: 20px;
    }
}